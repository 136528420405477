<template>
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  </head>
  <div id="print-area">
    <div class="form-header">
      <div class="header-content">
        <div class="img-head"><img src="/img/logo.png" alt="Logo CBM" class="logo"></div>
        <div class="txt-head">
          <h2>Ordem de Serviço</h2>
        </div>
      </div>
    </div>
    <div class="form-body">
      <p><i class="fas fa-user"></i> <strong>Cliente:</strong> {{ clienteData.display_name }}</p>
      <p><i class="fas fa-user-tie"></i> <strong>Responsável:</strong> {{ formData.responsavel }}</p>
      <p><i class="fa-solid fa-address-card"></i> <strong>Solicitante:</strong> {{ formData.contato }}</p>
      <p><i class="fas fa-phone"></i> <strong>Telefone: </strong> {{ formData.telefone }}</p>
      <p><i class="fas fa-clipboard-list"></i> <strong>Solicitação:</strong> {{ formData.solicitacao }}</p>
      <br>
      <div class="servico-executado">
        <h4>Serviços Executados:</h4>
        <p>{{ formData.servico_executado }}</p>
      </div>
      <div class="products-section">
        <h4>Produtos</h4>
        <table class="products-table">
          <thead>
            <tr>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Preço</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="produto in formData.produtos" :key="produto.id">
              <td>{{ produto.produto }}</td>
              <td>{{ produto.quantidade }}</td>
              <td>{{ formatCurrency(produto.preco_produto) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="costs-signature">
        <div class="other-costs">
          <h4>Outros Custos</h4>
          <p><strong>Valor da Mão de Obra:</strong> {{ formatCurrency(formData.preco_mao_obra) }}</p>
          <p class="highlight"><strong>Valor Total:</strong> {{ formatCurrency(formData.valor_total) }}</p>
        </div>

        <div class="client-signature">
          <h4>Assinatura do Cliente</h4>
          <p style="margin-top: 60px;">____________________________________________</p>
          <p style="text-align: right;"></p>
        </div>
      </div>
    </div>

    <div class="form-footer">
      <p><i class="fas fa-file-invoice"></i> Número da Nota: {{ id }}</p>
      <p style="text-align: right;"><i class="fas fa-calendar-day"></i> {{ formData.data_cadastro }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/axios';
import ClienteServiceRS from '@/services/ClienteServiceRasao';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const route = useRoute();
const formData = ref({});
const clienteData = ref({});

function formatCurrency(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
}

let id = 0;

onMounted(async () => {
  id = route.params.id;

  const response = await axios.get(`/ordemservico/${id}`);
  formData.value = response.data;

  const clientes = await ClienteServiceRS.fetchClientes();
  clienteData.value = clientes.find(cliente => cliente.id === formData.value.cliente_id);

  formData.value.data_cadastro = format(
    new Date(formData.value.data_cadastro),
    "dd 'de' MMMM 'de' yyyy",
    { locale: ptBR }
  );

  await nextTick();

  window.print();
});
</script>

<style scoped>
@page {
    size: A4;
    margin: 22px;
}


  #print-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 8px;
}

.servico-executado {
  border: 1px solid #4a90e2;
  border-radius: 8px;
  padding: 10px;
  padding-left: 5px;
  padding-bottom: 150px;
  background-color: #fff;
  margin-left: -10px;
  width: calc(100% + 10px);
  white-space: pre-wrap;
}


  .form-header {
    margin-bottom: 20px;
    border-bottom: 1px solid #4a90e2;
    padding-bottom: 10px;
  }

  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .img-head {
    flex-shrink: 0;
  }

  .txt-head {
    flex-grow: 1;
    text-align: center;
  }

  .txt-head h2 {
    font-family: 'Arial', sans-serif;
    font-size: 38px;
    font-weight: bold;
    color: #0c0247;
    text-transform: uppercase;
    background: #0c0247;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }

  .logo {
    max-width: 150px;
  }

  .form-body {
  flex-grow: 1;
  margin-top: 20px;
}

  .form-body p {
    margin: 8px 0;
    font-size: 16px;
    color: #333;
  }

  .form-body p strong {
    color: #000;
  }

  .products-section {
    margin-top: 20px;
  }

  .products-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }

  .products-table th,
  .products-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  .products-table th {
    background-color: #f9f9f9;
    font-weight: 700;
  }

  .products-table td {
    background-color: #fff;
  }

  .highlight {
    font-size: 30px;
    font-weight: bold;
    color: #93b7ee;
    background-color: #c3e4f7;
    border-radius: 4px;
    display: inline-block;
  }

  .costs-signature {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
  }

  .other-costs {
    flex: 1;
    margin-right: 20px;
  }

  .client-signature {
    flex: 1;
    text-align: center;
  }

  .client-signature h4 {
    margin-bottom: 20px;
  }

  .form-footer {
  border-top: 1px solid #4a90e2;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #333;
}
</style>
