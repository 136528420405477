    <template>
        <div class="main-content position-relative max-height-vh-100 h-100">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header pb-0">
                                <div class="d-flex align-items-center">
                                    <p class="mb-0">Chamado Id</p>
                                </div>
                            </div>
                            <div style="min-height:60vh" class="card-body">
                                <div>
                                    <form @submit.prevent="handleSubmit">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="cliente_id" class="form-label">Cliente*</label>
                                                <v-select id="cliente_id" class="form-control" v-model="cliente_id"
                                                    :options="clientes" label="display_name"
                                                    placeholder="Selecione o Cliente" required></v-select>
                                            </div>

                                            <div class="col-md-6">
                                                <label for="titulo" class="form-label">Título*</label>
                                                <input id="titulo" v-model="titulo" class="form-control"
                                                    placeholder="Título" required />
                                            </div>

                                            <div class="col-md-6">
                                                <label for="celular" class="form-label">Celular*</label>
                                                <input id="celular" v-model="celular" class="form-control"
                                                    placeholder="Celular" required />
                                            </div>


                                            <div class="col-md-6">
                                                <label for="descricao" class="form-label">Descrição*</label>
                                                <textarea rows="9" id="descricao" v-model="descricao"
                                                    class="form-control" placeholder="Descreva a descrição do Chamado"
                                                    required></textarea>
                                            </div>

                                            <div class="col-md-6">
                                                <label for="solucao" class="form-label">Solução*</label>
                                                <textarea rows="9" id="solucao" v-model="solucao" class="form-control"
                                                    placeholder="Descreva a descrição da Solução"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-4">
                                                <label for="status" class="form-label">Status*</label>
                                                <select id="status" v-model="status" class="form-control"
                                                    @change="handleStatusChange">
                                                    <option value="Aberto">Aberto</option>
                                                    <option value="Em Andamento">Em Andamento</option>
                                                    <option value="Agendado">Agendado</option>
                                                    <option value="Cancelado">Cancelado</option>
                                                    <option value="Realizado">Realizado</option>
                                                </select>
                                            </div>

                                            <div class="col-md-4">
                                                <label for="produto_id" class="form-label">Produto*</label>
                                                <select id="produto_id" class="form-control" v-model="produto_id">
                                                    <option disabled selected value="">Selecione um Produto</option>
                                                    <option v-for="produto in produtos" :key="produto.id"
                                                        :value="produto.id">
                                                        {{ produto.nome }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-md-4">
                                                <label for="categoria_id" class="form-label">Categoria*</label>
                                                <select id="categoria_id" class="form-control" v-model="categoria_id">
                                                    <option value="" disabled selected>Selecione a Categoria</option>
                                                    <option v-for="categoria in categorias" :key="categoria.id"
                                                        :value="categoria.id">
                                                        {{ categoria.nome }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="data_abertura" class="form-label">Data de Abertura*</label>
                                                <input id="data_abertura" v-model="data_abertura" type="datetime-local"
                                                    class="form-control" required />
                                            </div>

                                            <div v-if="status === 'Agendado'" class="col-md-6">
                                                <label for="data_agendamento" class="form-label">Data de
                                                    Agendamento</label>
                                                <input id="data_agendamento" v-model="data_agendamento"
                                                    type="datetime-local" class="form-control"
                                                    placeholder="Data de Agendamento" />
                                            </div>

                                            <div v-if="status === 'Cancelado' || status === 'Realizado'"
                                                class="col-md-6">
                                                <label for="data_finalizado" class="form-label">Data de
                                                    Encerramento</label>
                                                <input id="data_finalizado" v-model="data_finalizado"
                                                    type="datetime-local" class="form-control" required />
                                            </div>

                                            <div v-if="status === 'Aberto' || status === 'Em Andamento'"
                                                class="col-md-6">
                                                <label for="data_agendamento_disabled" class="form-label">Data
                                                    indisponível neste status*</label>
                                                <div class="form-control disabled-field">
                                                    <i class="fa fa-warning" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <hr>
                                            </div>
                                            <div class="col-6">
                                                <router-link to="/Clientes" class="btn btn-default float-start"><i
                                                        class="fa fa-arrow-left"></i> Voltar</router-link>
                                            </div>
                                            <div class="col-6">
                                                <button type="submit" class="btn btn-primary float-end"
                                                    :disabled="isSubmitting">
                                                    <i v-if="isSubmitting" class="fa fa-spinner fa-spin"></i>
                                                    <i v-else class="fa fa-save"></i> Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import ClienteService from '@/services/ClienteServiceNomeRasao';

export default {
    components: {
        vSelect,
    },
    name: 'TicketForm',
    setup() {
        const router = useRouter();
        const loading = ref(true);
        const isSubmitting = ref(false);

        const titulo = ref('');
        const descricao = ref('');
        const solucao = ref('');
        const cliente_id = ref('');
        const usuario = ref('');
        const status = ref('Aberto');
        const data_abertura = ref(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
        const data_agendamento = ref('');
        const data_finalizado = ref('');
        const produto_id = ref('');
        const categoria_id = ref('');
        const celular = ref('');

        const clientes = ref([]);
        const produtos = ref([]);
        const categorias = ref([]);

        onMounted(() => {
            fetchClientes();
            fetchProdutos();
            fetchCategorias();
            setUsuarioId();
        });

        const fetchClientes = async () => {
            loading.value = true;
            try {
                clientes.value = await ClienteService.fetchClientes();
            } finally {
                loading.value = false;
            }
        };

        const fetchProdutos = async () => {
            const response = await axios.get('/produtos');
            produtos.value = response.data;
        };

        const fetchCategorias = async () => {
            const response = await axios.get('/categorias');
            categorias.value = response.data;
        };

        const setUsuarioId = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.id) {
                usuario.value = user.name;
            }
        };

        const handleStatusChange = () => {
            if (status.value !== 'Cancelado' && status.value !== 'Realizado') {
                data_finalizado.value = '';
            }
            if (status.value !== 'Agendado') {
                data_agendamento.value = '';
            }
        };

        const handleSubmit = async () => {
            if (isSubmitting.value) return;
            isSubmitting.value = true;

            // Crie um objeto com os dados do chamado
            const ticketData = {
                titulo: titulo.value,
                descricao: descricao.value,
                solucao: solucao.value,
                usuario_id: JSON.parse(localStorage.getItem('user')).id,
                cliente_id: cliente_id.value.id,
                status: status.value,
                data_abertura: data_abertura.value,
                data_agendamento: data_agendamento.value,
                data_finalizado: data_finalizado.value,
                produto_id: produto_id.value,
                categoria_id: categoria_id.value,
                celular: celular.value
            };
            try {

                console.log(ticketData)
                // Envia a requisição POST para a API
                const response = await axios.post('/chamados', ticketData);

                // Exibe uma mensagem de sucesso
                Swal.fire('Sucesso', `Chamado criado com sucesso! ID: ${response.data.id}`, 'success');

                // Redireciona para a página de edição do chamado
                router.push(`/EditarChamado/${response.data.id}`);
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    Swal.fire('Erro', 'Campos inválidos, verifique o preenchimento e tente novamente.', 'error');
                } else {
                    Swal.fire('Erro', `Erro ao criar chamado. ${error.message}`, 'error');
                }
                console.log(error);
            }
            finally {
                isSubmitting.value = false; // Reativa o botão após o envio
            }
        };

        return {
            titulo,
            descricao,
            solucao,
            cliente_id,
            usuario,
            status,
            data_abertura,
            data_agendamento,
            data_finalizado,
            produto_id,
            categoria_id,
            clientes,
            produtos,
            celular,
            categorias,
            handleStatusChange,
            isSubmitting,
            handleSubmit,
        };
    },
};
</script>

<style scoped>
.form-control {
    padding: 10px;
    width: 100%;
}

.swal2-confirm {
    padding: 10px 20px;
    background-color: #3085d6;
    color: white;
    border: none;
    cursor: pointer;
}
</style>
