import { createRouter, createWebHistory } from 'vue-router';
import DashBoardPage from '@/pages/DashBoardPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import ProdutosPage from '../pages/Produtos/ListProdutos.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import CategoriasPage from '../pages/Categorias/ListCategoriaProdutos.vue';
import ClientesPage from '../pages/Clientes/ListClientes.vue';
import ChamadosPage from '../pages/Chamados/ListChamado.vue';
import AbrirChamado from '../pages/Chamados/Components/AddChamado.vue';
import EditarChamado from '../pages/Chamados/Components/EditChamado.vue';
import CadastroCliente from '../pages/Clientes/Components/AddCliente.vue';
import EditarCliente from '../pages/Clientes/Components/EditCliente.vue';
import UsuariosPage from '../pages/Usuarios/ListUsuarios.vue';
import AbrirOS from '@/pages/OrdemServico/Components/AddOrdemServico.vue';
import EditarOS from '@/pages/OrdemServico/Components/EditOrdemServico.vue';
import OrdensServicoCriados from '@/pages/OrdemServico/ListOrdemServico.vue';
import ImpressaoOS from '../components/ImpressaoOS.vue';
import ListaBanidos from '@/pages/ZapBot/ListExclusaoBot.vue';
import UploadApp from '../pages/ProgramasCBM/AddExecutavel.vue';
import ListaExecutaveis from '@/pages/ProgramasCBM/ListExecutavel.vue';

const routes = [
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuariosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/',
        name: 'Home',
        component: DashBoardPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditarCliente/:id',
        name: 'EditarCliente',
        component: EditarCliente,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/Produtos',
        name: 'ProdutosPage',
        component: ProdutosPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditarChamado/:id',
        name: 'EditarChamado',
        component: EditarChamado,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/AbrirChamado',
        name: 'AbrirChamado',
        component: AbrirChamado,
        meta: { requiresAuth: true }
    },
    {
        path: '/OrdensServicoCriados',
        name: 'Ordens de Serviço',
        component: OrdensServicoCriados,
        meta: { requiresAuth: true }
    },
    {
        path: '/AbrirOS',
        name: 'Ordem de Serviço',
        component: AbrirOS,
        meta: { requiresAuth: true }
    },
    {
        path: '/EditarOS/:id',
        name: 'Editar Ordem de Serviço',
        component: EditarOS,
        meta: { requiresAuth: true }
    },
    {
        path: '/ListaBanidos',
        name: 'ListaBanidos',
        component: ListaBanidos,
        meta: { requiresAuth: true }
    },
    {
        path: '/UploadApp',
        name: 'UploadApp',
        component: UploadApp,
        meta: { requiresAuth: true }
    },
    {
        path: '/ListaExecutaveis',
        name: 'ListaExecutaveis',
        component: ListaExecutaveis,
        meta: { requiresAuth: true }
    },
    {
        path: '/categorias',
        name: 'Categorias',
        component: CategoriasPage,
    },
    {
        path: '/chamados',
        name: 'Chamados',
        component: ChamadosPage,
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: ClientesPage,
    },
    {
        path: '/CadastroCliente',
        name: 'CadastroCliente',
        component: CadastroCliente,
    },    
    {   path: '/print/:id',
        name: 'Print',
        component: ImpressaoOS
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    },
    // Outras rotas
    {
        path: '/:pathMatch(.*)*', // Captura todas as rotas não definidas
        redirect: { name: '404' } // Redireciona para a página de login
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('jwt'); // Verifica se há um token armazenado

    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ name: 'Login' }); // Redireciona para a página de login se não autenticado
    } else {
        next(); // Permite a navegação se autenticado ou se a rota não requer autenticação
    }
});

export default router;