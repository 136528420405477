<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <button class="nav-link" :class="{ active: activeTab === 'div_form' }"
                                    @click="showDiv('div_form')">
                                    <i class="fa fa-user"></i> Cadastro
                                </button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" :class="{ active: activeTab === 'div_produtos' }"
                                    @click="showDiv('div_produtos')">
                                    <i class="fa fa-box"></i> Sistemas/Produtos
                                </button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" :class="{ active: activeTab === 'div_arquivos' }"
                                    @click="showDiv('div_arquivos')">
                                    <i class="fa fa-file"></i> Upload
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div>
                            <div><strong>ClienteId={{ clientId }}</strong></div>
                        </div>
                        <div v-show="activeTab === 'div_form'">
                            <form @submit.prevent="handleSubmit">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="razao_social" class="form-label">Razão Social*</label>
                                        <input id="razao_social" v-model="formData.razao_social" class="form-control"
                                            placeholder="Razão Social" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="nome_fantasia" class="form-label">Nome Fantasia*</label>
                                        <input id="nome_fantasia" v-model="formData.nome_fantasia" class="form-control"
                                            placeholder="Nome Fantasia" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="cnpj" class="form-label">CNPJ*</label>
                                        <input id="cnpj" v-model="formData.cnpj" class="form-control" placeholder="CNPJ"
                                            required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="endereco" class="form-label">Endereço*</label>
                                        <input id="endereco" v-model="formData.endereco" class="form-control"
                                            placeholder="Endereço" required />
                                    </div>

                                    <div class="col-md-12">
                                        <label for="obs" class="form-label">Observações / Anotações*</label>
                                        <textarea id="obs" style="min-height: 200px;" v-model="formData.obs"
                                            class="form-control" placeholder="Descrição" required></textarea>
                                    </div>

                                    <div class="col-md-6">
                                        <label for="email" class="form-label">Email*</label>
                                        <input id="email" v-model="formData.email" class="form-control"
                                            placeholder="Email" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="telefone" class="form-label">Telefone*</label>
                                        <input id="telefone" v-model="formData.telefone" class="form-control"
                                            placeholder="Telefone" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="ie" class="form-label mt-5">Inscrição Estadual*</label>
                                        <input id="ie" v-model="formData.ie" class="form-control"
                                            placeholder="Digite aqui a Inscrição Estadual" />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="im" class="form-label mt-5">Inscrição Municipal*</label>
                                        <input id="im" v-model="formData.im" class="form-control"
                                            placeholder="Digite aqui a Inscrição Municipal" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                    <div class="col-6">
                                        <router-link to="/Clientes" class="btn btn-default float-start btn-lg">
                                            <i class="fa fa-arrow-left"></i> Voltar
                                        </router-link>
                                    </div>
                                    <div class="col-6">
                                        <button type="submit" class="btn btn-primary float-end btn-lg">
                                            <i class="fa fa-save"></i> Salvar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-show="activeTab === 'div_produtos'" class="painel">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card mb-4">
                                        <div class="card-header pb-0">
                                            <h6>Produtos do Cliente</h6>
                                        </div>
                                        <div class="card-body px-0 pt-0 pb-2">
                                            <div v-if="isLoadingProducts" class="text-center py-5">
                                                <i class="fas fa-spinner fa-spin fa-3x"></i>
                                                <p class="mt-3">Carregando produtos...</p>
                                            </div>
                                            <div v-else>
                                                <div class="table-responsive p-0" v-if="clientProducts.length">
                                                    <table class="table align-items-center mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                    Nome do Produto
                                                                </th>
                                                                <th
                                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                    Status
                                                                </th>
                                                                <th
                                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                    Número de Licença
                                                                </th>
                                                                <th
                                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                    Ações
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="product in clientProducts" :key="product.id">
                                                                <td>
                                                                    <div class="d-flex px-2 py-1">
                                                                        <div
                                                                            class="d-flex flex-column justify-content-center">
                                                                            <h6 class="mb-0 text-sm">{{ product.nome }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        :class="['badge', product.pivot.ativo ? 'bg-success' : 'bg-danger']">
                                                                        {{ product.pivot.ativo ? 'Ativo' : 'Inativo' }}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {{ product.pivot.num_licenca || 'N/A' }}
                                                                </td>
                                                                <td>
                                                                    <button @click="toggleProductStatus(product.id)"
                                                                        class="btn btn-sm me-2"
                                                                        :class="product.pivot.ativo ? 'btn-warning' : 'btn-success'">
                                                                        {{ product.pivot.ativo ? 'Desativar' : 'Ativar'
                                                                        }}
                                                                    </button>
                                                                    <button @click="editLicense(product)"
                                                                        class="btn btn-info btn-sm me-2">
                                                                        <i class="far fa-edit me-2"></i>Editar Licença
                                                                    </button>
                                                                    <button @click="removeProduct(product.id)"
                                                                        class="btn btn-danger btn-sm">
                                                                        <i class="far fa-trash-alt me-2"></i>Remover
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div v-else class="text-center py-4">
                                                    <p class="text-sm text-secondary mb-0">Este cliente não possui
                                                        produtos associados.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card mb-4">
                                        <div class="card-header pb-0">
                                            <h6>Adicionar Produto</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <select v-model="selectedProductId" class="form-control"
                                                        :disabled="isAddingProduct">
                                                        <option value="">Selecione um produto para adicionar</option>
                                                        <option v-for="product in availableProducts" :key="product.id"
                                                            :value="product.id">
                                                            {{ product.nome }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <button @click="addProduct" class="btn btn-primary w-100"
                                                        :disabled="isAddingProduct">
                                                        <i v-if="isAddingProduct"
                                                            class="fas fa-spinner fa-spin me-2"></i>
                                                        <i v-else class="fas fa-plus me-2"></i>
                                                        {{ isAddingProduct ? 'Adicionando...' : 'Adicionar Produto' }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-show="activeTab === 'div_arquivos'" class="painel">
                            <form @submit.prevent="handleSubmitFiles">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="file_name" class="form-label">Nome do Anexo*</label>
                                        <input id="file_name" v-model="fileName" class="form-control"
                                            placeholder="Nome do Anexo" required />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="file" class="form-label">Arquivo</label>
                                        <input id="file" class="form-control" type="file"
                                            accept="video/mp4, image/*, application/pdf" @change="handleFileChange"
                                            required />
                                    </div>
                                    <div class="col-md-4">
                                        <br>
                                        <button type="submit" class="btn btn-primary float-end">
                                            <i class="fa fa-save"></i> Enviar Anexo
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <hr />
                                </div>
                                <div v-if="files.length" class="row">
                                    <div v-for="file in files" :key="file.id" class="col-md-4">
                                        <div class="file-man-box">
                                            <a :href="file.url" class="file-download" target="_blank">
                                                <i class="fa fa-download"></i>
                                            </a>
                                            <div class="file-img-box">
                                                <i class="fa fa-file"></i>
                                            </div>
                                            <div class="file-man-title">
                                                <h5 class="mb-0 text-overflow">{{ file.name }}</h5>
                                                <p class="mb-0"><small>{{ formatDate(file.uploaded_at) }}</small></p>
                                                <p class="mb-0"><small>{{ file.client_name }}</small></p>
                                            </div>
                                            <button type="button" @click="deleteFile(file.id)"
                                                class="btn btn-danger btn-sm">
                                                <i class="fa fa-times"></i> Deletar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                    <div class="col-6">
                                        <router-link to="/Clientes" class="btn btn-default float-start btn-lg">
                                            <i class="fa fa-arrow-left"></i> Voltar
                                        </router-link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '@/config';

export default {
    data() {
        return {
            formData: {
                razao_social: '',
                nome_fantasia: '',
                cnpj: '',
                endereco: '',
                obs: '',
                email: '',
                telefone: '',
                ie: '',
                im: '',
                isLoadingProducts: false,
                isAddingProduct: false,
            },
            clientId: this.$route.params.id,
            activeTab: 'div_form',
            clientProducts: [],
            availableProducts: [],
            selectedProductId: '',
            file: null,
            fileName: '',
            filePath: '',
            fileSize: '',
            files: [],
            apiBaseUrl: API_BASE_URL,
        };
    },
    created() {
        this.fetchClienteData();
        this.fetchFiles();
        this.fetchClientProducts();
        this.fetchAvailableProducts();
    },
    methods: {

        async fetchClienteData() {
            try {
                const clienteId = this.$route.params.id;
                const response = await axios.get(`/clientes/${clienteId}`);
                this.formData = response.data;
            } catch (error) {
                Swal.fire('Erro', 'Erro ao buscar dados do cliente.', 'error');
            }
        },

        async fetchClientProducts() {
            this.isLoadingProducts = true;
            try {
                const clientId = this.$route.params.id;
                const response = await axios.get(`/clientes/${clientId}/produtos`);
                this.clientProducts = response.data;
                console.log('Fetched client products:', this.clientProducts);
            } catch (error) {
                console.error('Error fetching client products:', error);
                Swal.fire('Erro', 'Erro ao buscar produtos do cliente.', 'error');
            } finally {
                this.isLoadingProducts = false;
            }
        },

        async editLicense(product) {
            const { value: newLicense } = await Swal.fire({
                title: 'Editar Número de Licença',
                input: 'number',
                inputLabel: `Número de Licença para ${product.nome}`,
                inputValue: product.pivot.num_licenca || '',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'Você precisa inserir um número de licença!';
                    }
                }
            });

            if (newLicense) {
                try {
                    const clientId = this.$route.params.id;
                    await axios.put(`/clientes/${clientId}/produtos/${product.id}/updateLicenca`, {
                        num_licenca: parseInt(newLicense)
                    });

                    const updatedProduct = this.clientProducts.find(p => p.id === product.id);
                    if (updatedProduct) {
                        updatedProduct.pivot.num_licenca = parseInt(newLicense);
                    }

                    Swal.fire('Sucesso', 'Número de licença atualizado com sucesso!', 'success');
                } catch (error) {
                    console.error('Error updating license number:', error);
                    Swal.fire('Erro', 'Erro ao atualizar o número de licença.', 'error');
                }
            }
        },

        async toggleProductStatus(productId) {
            const result = await Swal.fire({
                title: 'Tem certeza?',
                text: "Você deseja alterar o status deste produto?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, alterar!',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                try {
                    const clientId = this.$route.params.id;
                    const response = await axios.post(`/clientes/${clientId}/produtos/${productId}/toggle-ativo`);

                    const updatedProduct = this.clientProducts.find(p => p.id === productId);
                    if (updatedProduct) {
                        updatedProduct.pivot.ativo = response.data.ativo;
                    }

                    Swal.fire('Sucesso', 'Status do produto alterado com sucesso!', 'success');
                } catch (error) {
                    console.error('Error toggling product status:', error);
                    Swal.fire('Erro', 'Erro ao alterar status do produto.', 'error');
                }
            }
        },

        async fetchAvailableProducts() {
            try {
                const response = await axios.get('/produtos');
                this.availableProducts = response.data;
            } catch (error) {
                Swal.fire('Erro', 'Erro ao buscar produtos disponíveis.', 'error');
            }
        },

        async addProduct() {
            if (!this.selectedProductId) {
                Swal.fire('Erro', 'Selecione um produto para adicionar.', 'error');
                return;
            }

            this.isAddingProduct = true;
            try {
                const clientId = this.$route.params.id;
                await axios.post(`/clientes/${clientId}/produtos`, {
                    produto_id: this.selectedProductId
                });
                await this.fetchClientProducts();
                this.selectedProductId = '';
                Swal.fire('Sucesso', 'Produto adicionado com sucesso!', 'success');
            } catch (error) {
                console.error('Error adding product:', error);
                Swal.fire('Erro', 'Erro ao adicionar produto.', 'error');
            } finally {
                this.isAddingProduct = false;
            }
        },
        async removeProduct(productId) {
            const result = await Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá reverter esta ação!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, remover!',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                try {
                    const clientId = this.$route.params.id;
                    await axios.delete(`/clientes/${clientId}/produtos/${productId}`);
                    await this.fetchClientProducts();
                    Swal.fire('Removido!', 'O produto foi removido com sucesso.', 'success');
                } catch (error) {
                    console.error('Error removing product:', error);
                    Swal.fire('Erro', 'Erro ao remover produto.', 'error');
                }
            }
        },

        async fetchFiles() {
            try {
                const clientId = this.$route.params.id;
                const response = await axios.get(`/files?client_id=${clientId}`);
                this.files = response.data.map(file => ({
                    ...file,
                    url: `${this.apiBaseUrl}${file.url}`
                }));
            } catch (error) {
                Swal.fire('Erro', 'Erro ao buscar arquivos.', 'error');
            }
        },
        async deleteFile(fileId) {
            const result = await Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, deletar!',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`/files/${fileId}`);
                    Swal.fire('Deletado!', response.data.message, 'success');
                    this.fetchFiles();
                } catch (error) {
                    Swal.fire('Erro', 'Erro ao deletar arquivo.', 'error');
                }
            }
        },
        async handleSubmit() {
            try {
                const clienteId = this.$route.params.id;
                await axios.put(`/clientes/${clienteId}`, this.formData);
                Swal.fire('Sucesso', 'Cliente atualizado com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', 'Erro ao atualizar cliente.', 'error');
            }
        },
        async handleSubmitFiles() {
            if (!this.file) {
                Swal.fire('Erro', 'Nenhum arquivo selecionado.', 'error');
                return;
            }

            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('name', this.fileName);
            formData.append('client_id', this.$route.params.id);

            Swal.fire({
                title: 'Enviando arquivo...',
                html: '<div id="progress-container" style="width: 100%;"><div id="progress-bar" style="width: 0%; height: 20px; background-color: #007bff;"></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            try {
                await axios.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        document.getElementById('progress-bar').style.width = `${percentCompleted}%`;
                    }
                });

                Swal.fire({
                    title: 'Sucesso',
                    text: 'Arquivo enviado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                this.fetchFiles();
            } catch (error) {
                Swal.fire({
                    title: 'Erro',
                    text: 'Erro ao enviar arquivo.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        },
        handleFileChange(event) {
            this.file = event.target.files[0];
            this.fileSize = this.file ? `${(this.file.size / 1024).toFixed(2)} KB` : '';
        },
        showDiv(DivId) {
            this.activeTab = DivId;
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('pt-BR');
        }
    }
};
</script>

<style scoped>
.nav-link.active {
    font-weight: bold;
}

.painel {
    min-height: 60vh;
}

.file-man-box {
    padding: 20px;
    border: 1px solid #e3eaef;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
}

.file-man-box .file-close {
    color: #f1556c;
    position: absolute;
    line-height: 24px;
    font-size: 24px;
    right: 10px;
    top: 10px;
    visibility: hidden;
}

.file-man-box .file-img-box {
    line-height: 120px;
    text-align: center;
}

.file-man-box .file-img-box img {
    height: 64px;
}

.file-man-box .file-download {
    font-size: 32px;
    color: #98a6ad;
    position: absolute;
    right: 10px;
}

.file-man-box .file-download:hover {
    color: #313a46;
}

.file-man-box .file-man-title {
    padding-right: 25px;
}

.file-man-box:hover {
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
}

.file-man-box:hover .file-close {
    visibility: visible;
}

.text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-group-item button {
    margin-left: 10px;
}

.card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.table td,
.table th {
    white-space: nowrap;
}

.form-control {
    border: 1px solid #d2d6da;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.form-control:focus {
    border-color: #11cdef;
    outline: 0;
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1);
}

.btn-primary {
    background-color: #5e72e4;
    border-color: #5e72e4;
}

.btn-primary:hover {
    background-color: #324cdd;
    border-color: #324cdd;
}
</style>
