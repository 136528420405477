<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Carregando...</span>
                            </div>
                        </div>
                        <div v-else class="table-responsive">
                            <form @submit.prevent="submitForm">
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label for="cliente_id" class="form-label">
                                            <i class="fas fa-users"></i> Cliente*
                                        </label>
                                        <v-select id="cliente_id" class="form-control" v-model="formData.cliente_id"
                                            :options="clientes" label="display_name" placeholder="Selecione o Cliente"
                                            required></v-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="responsavel" class="form-label">
                                            <i class="fas fa-user-tie"></i> Responsável da Ordem de Serviço
                                        </label>
                                        <input type="text" class="form-control" id="responsavel"
                                            v-model="formData.responsavel" required>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="data_cadastro" class="form-label">
                                            <i class="fas fa-calendar-alt"></i> Data de Cadastro
                                        </label>
                                        <input type="date" class="form-control" id="data_cadastro"
                                            v-model="formData.data_cadastro" required>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="contato" class="form-label">
                                            <i class="fas fa-user"></i> Contato (nome da pessoa que solicitou)
                                        </label>
                                        <input type="text" class="form-control" id="contato" v-model="formData.contato"
                                            required>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="telefone" class="form-label">
                                            <i class="fas fa-phone"></i> Telefone
                                        </label>
                                        <input type="text" class="form-control" id="telefone"
                                            v-model="formData.telefone" placeholder="+55 " required
                                            @input="formatTelefone">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="solicitacao" class="form-label">
                                        <i class="fas fa-file-alt"></i> Solicitação
                                    </label>
                                    <textarea class="form-control" id="solicitacao" v-model="formData.solicitacao"
                                        rows="3" required></textarea>
                                </div>
                                <div class="mb-3">
                                    <label for="servico_executado" class="form-label">
                                        <i class="fas fa-cogs"></i> Serviço Executado
                                    </label>
                                    <textarea class="form-control" id="servico_executado"
                                        v-model="formData.servico_executado" rows="3" required></textarea>
                                </div>
                                <div v-if="produtos.length > 0" class="container-fluid">
                                    <div class="row text-center">
                                        <div class="col-md-4 d-flex justify-content-center align-items-center mt-1">
                                            <label for="produto" class="form-label">
                                                <i class="fas fa-box"></i> Produtos
                                            </label>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-center align-items-center mt-1">
                                            <label for="quantidade" class="form-label">
                                                <i class="fas fa-sort-numeric-up"></i> Quantidade
                                            </label>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-center align-items-center mt-1">
                                            <label for="preco" class="form-label">
                                                <i class="fas fa-dollar-sign"></i> Preço
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <br>
                                    <div v-for="(produto, index) in produtos" :key="index" class="mb-2">
                                        <div class="row">
                                            <div class="col-md-4 mb-2">
                                                <input type="text" class="form-control" placeholder="Produto"
                                                    v-model="produto.produto">
                                            </div>
                                            <div class="col-md-4 mb-2">
                                                <input type="number" class="form-control" placeholder="Quantidade"
                                                    v-model="produto.quantidade" min="1">
                                            </div>
                                            <div class="col-md-3 mb-2">
                                                <input type="number" class="form-control" placeholder="R$ 0,00"
                                                    v-model="produto.preco_produto" step="0.01">
                                            </div>
                                            <div class="col-md-1 d-flex align-items-center mb-2">
                                                <button type="button" class="btn btn-danger"
                                                    @click="removeProduto(index)">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary mt-2" @click="addProduto">
                                        <i class="fas fa-plus"></i> Adicionar Produto
                                    </button>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="preco_mao_obra" class="form-label">
                                            <i class="fas fa-hand-holding-usd"></i> Preço da Mão de Obra
                                        </label>
                                        <input type="number" class="form-control" id="preco_mao_obra"
                                            v-model="formData.preco_mao_obra" step="0.01">
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="valor_total" class="form-label">
                                            <i class="fas fa-dollar-sign"></i> Valor Total
                                        </label>
                                        <input type="number" class="form-control" id="valor_total"
                                            v-model="formData.valor_total" step="0.01" required>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success me-2 mt-2">
                                    <i class="fas fa-save"></i> Salvar Ordem de Serviço
                                </button>
                                <button type="button" @click="impressaoOS" class="btn btn-success mt-2">
                                    <i class="fas fa-file-pdf"></i> Gerar PDF
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from '@/axios';
import Swal from 'sweetalert2';
import { useRouter, useRoute } from 'vue-router';
import ClienteService from '@/services/ClienteServiceNomeRasao';

const router = useRouter();
const route = useRoute();

const formData = ref({
    cliente_id: '',
    responsavel: '',
    contato: '',
    telefone: '+55 ',
    data_cadastro: '',
    solicitacao: '',
    servico_executado: '',
    preco_mao_obra: 0,
    valor_total: 0
});

const produtos = ref([]);
const clientes = ref([]);
const loading = ref(false);

const formatTelefone = (event) => {
    let telefone = event.target.value.replace(/[^0-9+]/g, '');
    formData.value.telefone = telefone;
};

const fetchClientes = async () => {
    loading.value = true;
    try {
        clientes.value = await ClienteService.fetchClientes();
    } finally {
        loading.value = false;
    }
};

const fetchOrdemServico = async (id) => {
    loading.value = true;
    try {
        const response = await axios.get(`/ordemservico/${id}`);
        const data = response.data;

        const cliente = clientes.value.find(cliente => cliente.id === data.cliente_id);

        formData.value = {
            cliente_id: cliente ? cliente.display_name : '',
            responsavel: data.responsavel,
            contato: data.contato,
            telefone: data.telefone,
            data_cadastro: data.data_cadastro,
            solicitacao: data.solicitacao,
            servico_executado: data.servico_executado,
            preco_mao_obra: data.preco_mao_obra,
            valor_total: data.valor_total
        };

        produtos.value = data.produtos;
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Houve um problema ao carregar a Ordem de Serviço.',
            confirmButtonText: 'OK'
        });
        console.error('Error fetching ordem de servico:', error);
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    fetchClientes().then(() => {
        const id = route.params.id;
        if (id) {
            fetchOrdemServico(id);
        }
    });
});

const addProduto = () => {
    produtos.value.push({
        produto: '',
        quantidade: 1,
        preco_produto: 0
    });
};

const removeProduto = (index) => {
    produtos.value.splice(index, 1);
    calcularValorTotal();
};

const calcularValorTotal = () => {
    const totalProdutos = produtos.value.reduce((total, produto) => {
        const preco = parseFloat(produto.preco_produto) || 0;
        const quantidade = parseFloat(produto.quantidade) || 0;
        return total + (quantidade * preco);
    }, 0);
    formData.value.valor_total = (totalProdutos + parseFloat(formData.value.preco_mao_obra) || 0).toFixed(2);
};

watch(produtos, calcularValorTotal, { deep: true });
watch(() => formData.value.preco_mao_obra, calcularValorTotal);

const impressaoOS = () => {
    window.open(`/Print/${route.params.id}`, '_blank');
};

const submitForm = async () => {
    try {
        const selectedCliente = clientes.value.find(cliente => cliente.display_name === formData.value.cliente_id);

        await axios.put(`/ordemservico/${route.params.id}`, {
            ...formData.value,
            cliente_id: selectedCliente.id,
            produtos: produtos.value
        });
        Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Ordem de Serviço atualizada com sucesso.',
            confirmButtonText: 'OK'
        });
        router.push('/OrdensServicoCriados');
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Houve um problema ao atualizar a Ordem de Serviço.',
            confirmButtonText: 'OK'
        });
        console.error('Error updating ordem de servico:', error);
    }
};
</script>